import { brfv5 }    from './brfv5/brfv5__init.js'
import { configureCameraInput, configureFaceTracking, configureNumFacesToTrack }     from './brfv5/brfv5__configure.js'
import { startCamera, stopCamera }              from './utils/utils__camera.js'
import { drawInputMirrored, drawInput }        from './utils/utils__canvas.js'
import { SystemUtils }                      from './utils/utils__system.js'
import {updateColorWay, getAllSubmeshes, updateEnvironmentMap} from './utils/colorUtils.js'
import { enableDynamicPerformance } from './brfv5/brfv5__dynamic_performance.js'


import * as RotationController from "../3d/utils/rotationController.js"
import * as THREE                   from  'three'
import * as LightFuncs              from './lighting3D'
import * as dat from 'dat.gui';


const sceneScale = 2;
const WAITING_FRAMES_FOR_LIGHT_UPDATE = 10;


let DEBUG_MODE = false;
let USE_SHADOWS = true;
let _webcam, _imageData, _imageData2, _3dData, _backgroundCanvas;
let _brfv5Manager     = null;
let _brfv5Config      = null;
let _width            = 0;
let _height           = 0;
let setupReady        = false;
let _submeshes        = false;
let renderer, scene, camera, arElements, offsetGroup;
let headphones = null;
let head = null;
let headMaterial = null;
let gltf;
let currentFrame = 0;
let trackFaceCounter = 0;
let setupLights = false;
let fillLightBack, fillLightRight, fillLightLeft, ambientLight;
let frontLight, sceneBrightness, faceBrightness, frontLightProxy;
let frontLightAxis = new THREE.AxesHelper( 100 );
let frontLightDirection = new THREE.Vector3();
let finalLightPosition = new THREE.Vector3();
let headphonesExtension = 1;
let callback;
let videoPlane, videoCanvasTexture;
let ctx2;
let animator;
let activeCanvas, inactiveCanvas;
let activeImageData = null;
let cameraMode = null;
let oldRotations = new THREE.Vector3(0, 0, 0);
let oldPosition = new THREE.Vector3(0, 0, 0);
let oldScale = 0;
let headphonesSettings = {
    scale: 8.8,
    height: 63,
    depth: 134,
    rotation: 10,
    minAperture: 0.66,
    maxAperture: 0.82
}
let postPro1;
let postPro2;
let blurMaterial;
let ortoCamera;
let postProScene;
let postProPlaneAdded = false;
let postProPlane;
let postProNoiseMaterial;
let noiseTexture = null;
const CLAMP_BRIGHTNESS = 0.7;
let brightnessLimit = CLAMP_BRIGHTNESS;


const vertexShader = `

        varying vec4 vColor;

        void main() {

            normalize(normal);
            vColor = vec4(0.5 * normal + vec3(0.5), 1.);

            vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * modelViewPosition;

        }
`;

const fragmentShader = `

        varying vec4 vColor;

        void main() {

            gl_FragColor = vColor;

        }

`;


const quadShader = `
        varying vec2 vUV;

        void main() {

            vUV = uv;

            vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * modelViewPosition;
        }
`;

const noiseShader = `
    uniform sampler2D uTexture;
    uniform sampler2D uNoise;

    varying vec2 vUV;

    void main() {

        vec4 image = texture2D(uTexture, vUV);
        vec4 noise = texture2D(uNoise, vUV);

        gl_FragColor = 1.05 * mix(image, noise, vec4(0.35));
        gl_FragColor.a = 1.;

    }
`;

const blurShader = `

    uniform sampler2D texture;
    varying vec2 vUV;
    uniform vec2 axis;

    void main() {

        gl_FragColor = 2. * texture2D(texture, vUV);
        gl_FragColor += texture2D(texture, vUV + axis);
        gl_FragColor += texture2D(texture, vUV - axis);

        gl_FragColor /= 4.;

    }

`;

const stopVideoFeed = () => {
    stopCamera(_webcam);
}

//function used to reset the camera
const resetCamera= () => {
    stopCamera(_webcam);
    startCamera(_webcam, { width: 640, height: 480, frameRate: 30, facingMode: cameraMode });
}


//Function used to initiate the AR setup.
const init = (_gltf,_occlusionMesh, brfv5Manager, brfv5Config, _callback) => {

    gltf = _gltf;
    headphones = gltf.scene;
    head = _occlusionMesh;
    callback = _callback;

    const url = new URL(window.location.href);

    DEBUG_MODE = DEBUG_MODE || String(url.searchParams.get("debug")) == "true";
    USE_SHADOWS = USE_SHADOWS || String(url.searchParams.get("shadows")) == "true";

    const gui = new dat.GUI();
    gui.close();
    gui.add(headphonesSettings, "height", 40, 80, 1);
    gui.add(headphonesSettings, "scale", 7, 10, 0.1);
    gui.add(headphonesSettings, "depth", 100, 180, 1);
    gui.add(headphonesSettings, "rotation", 0, 20, 1);
    gui.add(headphonesSettings, "minAperture", 0.4, 0.7, 0.01);
    gui.add(headphonesSettings, "maxAperture", 0.7, 1, 0.01);

    return new Promise((resolve, reject) => {
        _webcam             = document.getElementById('_webcam');
        activeCanvas =   _imageData          = document.getElementById('_imageData');
        inactiveCanvas = _imageData2         = document.getElementById('_imageData2');
        _3dData             = document.getElementById('_3dData');


        _backgroundCanvas   = document.getElementById('_backgroundCanvas');
        ctx2 = _backgroundCanvas.getContext('2d')

        const url = new URL(window.location.href);
        const c = url.searchParams.get("camera");
        cameraMode = String(c) == "back" ? "environment" : "user";

        //Prepare the camera
        startCamera(_webcam, { width: 640, height: 480, frameRate: 30, facingMode: cameraMode }).then(({ video }) => {

            _width            = video.videoWidth;
            _height           = video.videoHeight;

            _imageData.width  = _width;
            _imageData.height = _height;

            _imageData2.width  = _width;
            _imageData2.height = _height;

            _3dData.width  = _width;
            _3dData.height = _height;

            _backgroundCanvas.width  = _width;
            _backgroundCanvas.height = _height;

            _brfv5Manager  = brfv5Manager;
            _brfv5Config   = brfv5Config;

            if(_brfv5Config !== null && _width > 0) {

                configureCameraInput(_brfv5Config, _width, _height);

                configureNumFacesToTrack(_brfv5Config, 1);

                enableDynamicPerformance(_brfv5Manager, _brfv5Config);

                _brfv5Manager.configure(_brfv5Config);
            }

            setup3DAssets();
            setupReady = true;
            resolve();

        }).catch(() => {
            reject()
        })

    })

}

const setup3DAssets = () => {

    //Prepare the renderer
    renderer        = new THREE.WebGLRenderer({
        canvas:           _3dData,
        powerPreference:  SystemUtils.isMobileOS ? 'low-power' : 'high-performance', //'high-performance' 'low-power'
        alpha:            true,
        antialias:        true,
        preserveDrawingBuffer: true
    });

    renderer.physicallyCorrectLights = true;
    renderer.shadowMap.enabled = USE_SHADOWS && !SystemUtils.isMobileOS;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.toneMappingExposure = 1;

    scene           = new THREE.Scene();
    camera          = new THREE.PerspectiveCamera(20.0, _3dData.width / _3dData.height, 20, 10000);
    arElements      = new THREE.Group();
    offsetGroup     = new THREE.Group();

    offsetGroup.position.set(0.0, -4.0, -3.0);

    arElements.add(offsetGroup);
    scene.add(arElements);


    fillLightBack  = new THREE.DirectionalLight(0xffffff, 0, 0, 2);
    fillLightBack.target = arElements;
    scene.add(fillLightBack);


    fillLightLeft  = new THREE.DirectionalLight(0xffffff, 0, 0, 2);
    fillLightLeft.target = arElements;
    scene.add(fillLightLeft);


    fillLightRight  = new THREE.DirectionalLight(0xffffff, 0, 0, 2);
    fillLightRight.target = arElements;
    scene.add(fillLightRight);


    ambientLight = new THREE.AmbientLight(0xffffff, 0);
    scene.add(ambientLight);


    frontLight = new THREE.PointLight(0xffffff, 0., 0, 2);
    scene.add(frontLight);


    frontLight.target = arElements;
    frontLight.shadow.mapSize.width = 2048;  // default
    frontLight.shadow.mapSize.height = 2048; // default
    frontLight.shadow.camera.near = 0.5;       // default
    frontLight.shadow.camera.far = 2000;      // default
    frontLight.shadow.bias = 0.001;
    frontLight.castShadow = true;


    let ww = SystemUtils.isMobileOS ? 53 : 94;
    videoCanvasTexture = new THREE.CanvasTexture(_imageData);
    videoPlane = new THREE.Mesh(new THREE.PlaneGeometry( ww, 71, 5 ), new THREE.MeshBasicMaterial({map: videoCanvasTexture}) );
    videoPlane.material.depthTest = false;
    videoPlane.rotation.y = Math.PI;
    videoPlane.position.z = 200;
    videoPlane.renderOrder = 0;
    scene.add(videoPlane);


    frontLightProxy= new THREE.Mesh( new THREE.SphereGeometry( 10, 32, 32 ), new THREE.MeshBasicMaterial( {color: 0xffffff} ) );

    //Start the lighting
    LightFuncs.setup(renderer, scene, camera, _imageData, frontLightProxy, frontLightAxis, DEBUG_MODE);

    renderer.setClearColor(0x000000, 0.0);

    headMaterial = new THREE.ShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader
    });

    _submeshes = getAllSubmeshes(headphones);
    _submeshes.forEach(m => {
        m.castShadow = true;
        m.receiveShadow = true;
    })

    setupLights = true;

    head.traverse((child) => {

        child.renderOrder = -1;

        if(child.material) {
            child.geometry.scale(1, 1, 1);
            child.position.z = 12;
            child.castShadow = true;
            child.receiveShadow = true;
            child.material = headMaterial;
            child.material.colorWrite = false;
            child.material.opacity    = 1;
            child.material.needsUpdate = true;
            child.visible = true;
        };
    });

    RotationController.stopRotations();
    animator = gltf.animator;

    offsetGroup.add(head);

    //Prepare things for postPro
    postProScene = new THREE.Scene();

    postProNoiseMaterial = new THREE.ShaderMaterial(
        {
            uniforms: {
                uTexture: { type: "t", value: null},
                uNoise: { type: "t", value: null}
            },
            vertexShader: quadShader,
            fragmentShader: noiseShader,
            side: THREE.DoubleSide
        }
    );

    blurMaterial = new THREE.ShaderMaterial(
        {
            uniforms: {
                texture: { type: "t", value: null},
                axis: { type: "v2", value: null}
            },
            vertexShader: quadShader,
            fragmentShader: blurShader,
            side: THREE.DoubleSide
        }
    );

    let imageNoise = new Image();
    imageNoise.onload = () => {
        noiseTexture = new THREE.Texture();
        noiseTexture.image = imageNoise;
        noiseTexture.magFilter = THREE.LinearFilter;
        noiseTexture.minFilter = THREE.LinearMipmapLinearFilter;
        noiseTexture.generateMipmaps = true;
        console.log("noise image loaded");
    }

    imageNoise.src = "./assets/CameraNoise.png"

    updateRenderer();

}

const updateHeadphonesExtension = value => {

    headphonesExtension = value;
}

const updateColor = (colorway) => {
    if(!_submeshes) return;
    brightnessLimit = colorway.name == "black" ? 1 : CLAMP_BRIGHTNESS;
    updateColorWay(_submeshes, colorway, LightFuncs.environmentMap, "video");
}

const trackFace = () => {

    trackFaceCounter ++;

    if(!_brfv5Manager || !_brfv5Config || !_imageData || !_imageData2 || !setupReady) { return }

    if(updateActiveCanvas()) {

        //Don't update the blur if it's on mobile.
        if(!SystemUtils.isMobileOS) {
            if(cameraMode == "user") {
                drawInputMirrored(ctx2, _width, _height, _webcam);
            } else {
                drawInput(ctx2, _width, _height, _webcam);
            }
        }

        const ctxActive = activeCanvas.getContext('2d');
        _brfv5Manager.update(ctxActive.getImageData(0, 0, _width, _height));
    }

    if(_brfv5Config.enableFaceTracking) {
        const faces      = _brfv5Manager.getFaces()

        arElements.visible = false;

        const face = faces[0]

        if(face == null) {
            return;
        }

        if(face.state === brfv5.BRFv5State.FACE_TRACKING) {

            //Update the group position
            callback(false);
            updateByFace(face);

        } else {
            callback(true);
        }

        /*
        The lighting functions are heavy (GPU intensive) so they should not be called on every frame,
        each function should be called on a different frame, and on regular time steps.
         */

        if((currentFrame % WAITING_FRAMES_FOR_LIGHT_UPDATE == 0 || setupLights)  ) {

            videoPlane.visible = false;

            //Update the ambient texture
            LightFuncs.updateAmbientTexture();


            //The fill light intensity is defined by the brightness from the scene.
            const brightnessData = LightFuncs.updateSceneBrightness(headMaterial, headphones);

            sceneBrightness = 2 * Math.min(brightnessData.sceneBrightness, brightnessLimit);
            faceBrightness = 5000000 * Math.min(brightnessData.faceBrightness, brightnessLimit);


            //Update the position of the main front light in the scene
            if(USE_SHADOWS) frontLightDirection = LightFuncs.updateMainFrontLightPosition(headMaterial, headphones);

            setupLights = false;

            videoPlane.visible = !DEBUG_MODE;
        }

        currentFrame ++;

        const changeRate = 0.3;

        frontLight.position.x += changeRate  * (finalLightPosition.x - frontLight.position.x);
        frontLight.position.y += changeRate  * (finalLightPosition.y - frontLight.position.y);
        frontLight.position.z += changeRate  * (finalLightPosition.z - frontLight.position.z);

        frontLightProxy.position.x += changeRate  * (finalLightPosition.x - frontLightProxy.position.x);
        frontLightProxy.position.y += changeRate  * (finalLightPosition.y - frontLightProxy.position.y);
        frontLightProxy.position.z += changeRate  * (finalLightPosition.z - frontLightProxy.position.z);

        fillLightBack.intensity += changeRate * (sceneBrightness - fillLightBack.intensity);
        fillLightLeft.intensity += changeRate * (sceneBrightness - fillLightLeft.intensity);
        fillLightRight.intensity += changeRate * (sceneBrightness - fillLightRight.intensity);

        ambientLight.intensity += changeRate * (sceneBrightness - ambientLight.intensity);

        frontLight.intensity += changeRate * (faceBrightness - frontLight.intensity);

        offsetGroup.add(headphones);

        //this is done in here to avoid conflicts with the Headphones preview component
        headphones.scale.set(headphonesSettings.scale, headphonesSettings.scale, headphonesSettings.scale);
        headphones.position.y = -headphonesSettings.height + 12 * headphonesSettings.scale / 8.5 * headphonesExtension;
        headphones.position.z = headphonesSettings.depth;
        headphones.rotation.x = headphonesSettings.rotation * Math.PI / 180;
        headphones.rotation.y = 0;
        headphones.renderOrder = 2;


        animator.setAnimation("slide",  headphonesExtension, 0.5);
        animator.setAnimation("unfold", 0, 0);
        animator.setAnimation("bend", headphonesSettings.minAperture + (headphonesSettings.maxAperture - headphonesSettings.minAperture) * headphonesExtension, 1);

        animator.update();

        videoCanvasTexture.needsUpdate = true;


        //Save the render to a renderTarget
        renderer.setRenderTarget(postPro1);
        renderer.render(scene, camera);

        //Make the noise in the image
        renderer.setRenderTarget(postPro2);
        postProPlane.material = postProNoiseMaterial;
        postProNoiseMaterial.uniforms.uTexture.value = postPro1.texture;
        postProNoiseMaterial.uniforms.uNoise.value = noiseTexture;
        renderer.render(postProScene, ortoCamera);

        //Render the first part of the blur
        renderer.setRenderTarget(postPro1);
        postProPlane.material = blurMaterial;
        blurMaterial.uniforms.texture.value = postPro2.texture;
        blurMaterial.uniforms.axis.value = new THREE.Vector2(1 / renderer.domElement.width, 0);
        renderer.render(postProScene, ortoCamera);

        //Render the second part of the blur
        renderer.setRenderTarget(null);
        blurMaterial.uniforms.texture.value = postPro1.texture;
        blurMaterial.uniforms.axis.value = new THREE.Vector2(0, 1 / renderer.domElement.height);
        renderer.render(postProScene, ortoCamera);

    }
}

const captureImageData = () => {

    const response = {
        width: renderer.domElement.width,
        height: renderer.domElement.height,
        base64: renderer.domElement.toDataURL()
    }

    return response
}

const updateRenderer = () => {
    const cw    = _width  * sceneScale
    const ch    = _height * sceneScale
    const css   = cw < ch ? cw : ch
    const csl   = cw < ch ? ch : cw

    let nw      = 0
    let nh      = 0

    if(_width < _height) {

        nw        = css
        nh        = csl

    } else {

        nw        = csl
        nh        = css
    }

    renderer.domElement.width   = nw
    renderer.domElement.height  = nh

    camera.aspect = nw / nh

    camera.position.set(0, 0, 0);
    camera.lookAt(new THREE.Vector3(0, 0, 1));
    camera.updateProjectionMatrix();

    renderer.setSize(nw, nh, false);

    postPro1 = new THREE.WebGLRenderTarget(nw, nh, { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter} );
    postPro2 = new THREE.WebGLRenderTarget(nw, nh, { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter} );

    ortoCamera = new THREE.OrthographicCamera( nw / - 2, nw / 2, nh / 2, nh / - 2, - 10000, 10000 );

    if(!postProPlaneAdded) {
        postProPlaneAdded = true;
        postProPlane = new THREE.Mesh( new THREE.PlaneBufferGeometry( nw, nh ), postProNoiseMaterial);
        postProScene.add(postProPlane);
    }

}

const width = () => {
    return _width;
}

const height = () => {
    return _height;
}

const updateByFace = (face) => {

    const canvasWidth   = renderer.domElement.width;
    const canvasHeight  = renderer.domElement.height;

    const aspect = 640 / 480;

    let transform = { x: 0, y: 0, z: 0, rx: 0, ry: 0, rz: 0, scale: 0 };

    if(!scene || !face || !face.landmarks) {
        arElements.visible = false;
        return
    }

    let modelZ = 2725

    if(camera.isPerspectiveCamera) {

        modelZ = (2725 / 480) * (canvasHeight / sceneScale)
    }

    const si  = sceneScale
    const cw  = (canvasWidth  / si)
    const ch  = (canvasHeight / si)

    let scale =   face.scale * si * 0.0133
    let x     = -(face.translationX - cw * 0.5)  * si
    let y     = -(face.translationY - ch * 0.5)  * si
    let rx    = - face.rotationX * 1.30
    let ry    = - face.rotationY * 1.30
    let rz    =   face.rotationZ

    let ryp   = Math.abs(ry) / 30.0
    let rxp   = Math.abs(rx) / 30.0

    if(rx < 0) {

        rx *= 1.0 + ryp
        ry *= 0.95

        y -= rxp * 10

    } else {

        rx *= 1.33
        y += rxp * 7
    }

    if(ry < 0) {

        if(rx > 0)  {

            rz -= ryp + (ryp * rxp) * 10

        } else {

            rz += ryp + (ryp * rxp) * 10
        }

    } else {

        if(rx > 0)  {

            rz += ryp + (ryp * rxp) * 10

        } else {

            rz -= ryp + (ryp * rxp) * 10
        }
    }

    if(camera.isPerspectiveCamera) {

        rx -= (180 / Math.PI) * (Math.atan(y / modelZ)) // perspective camera needs an adjustment for ry.
        ry += (180 / Math.PI) * (Math.atan(x / modelZ)) // perspective camera needs an adjustment for ry.
    }

    const diffX       = (x - transform.x)
    const diffY       = (y - transform.y)
    const diffXAbs    = Math.abs(diffX)
    const diffYAbs    = Math.abs(diffY)

    transform.x       = transform.x + diffX * (diffXAbs < 1.0 ? 0.25 : (diffXAbs < 2.0 ? 0.50 : 0.75))
    transform.y       = transform.y + diffY * (diffYAbs < 1.0 ? 0.25 : (diffYAbs < 2.0 ? 0.50 : 0.75))
    transform.z       = modelZ
    transform.scale   = scale// * 0.01 * si * 1.33

    const diffRx      = (rx - transform.rx)
    const diffRy      = (ry - transform.ry)
    const diffRz      = (rz - transform.rz)
    const diffRxAbs   = Math.abs(diffRx)
    const diffRyAbs   = Math.abs(diffRy)
    const diffRzAbs   = Math.abs(diffRz)

    transform.rx      = transform.rx + diffRx * (diffRxAbs < 1.0 ? 0.25 : (diffRxAbs < 2.0 ? 0.50 : 0.75))
    transform.ry      = transform.ry + diffRy * (diffRyAbs < 1.0 ? 0.25 : (diffRyAbs < 2.0 ? 0.50 : 0.75))
    transform.rz      = transform.rz + diffRz * (diffRzAbs < 1.0 ? 0.25 : (diffRzAbs < 2.0 ? 0.50 : 0.75))


    let framePosition = new THREE.Vector3(transform.x, transform.y, transform.z);
    let frameRotations = new THREE.Vector3(transform.rx, transform.ry, transform.rz);

    const minRotationAngleExpected = 8;
    let dd = framePosition.distanceTo(oldPosition);
    let relX = Math.abs(frameRotations.x - oldRotations.x) * 180 / Math.PI;
    let relY = Math.abs(frameRotations.y - oldRotations.y) * 180 / Math.PI;
    let relZ = Math.abs(frameRotations.z - oldRotations.z) * 180 / Math.PI;
    let relScale = Math.abs(transform.scale - oldScale);

    arElements.visible = true;

    //reposition things if necessary
    if(dd > 10 || relX > minRotationAngleExpected || relY > minRotationAngleExpected || relZ > minRotationAngleExpected || relScale > 1.1) {

        arElements.position.set(transform.x * aspect, transform.y * aspect, transform.z);
        arElements.rotation.set((Math.PI / 180) * (transform.rx), (Math.PI / 180) * (transform.ry), (Math.PI / 180) * (transform.rz));
        arElements.scale.set(transform.scale, transform.scale, transform.scale);

        frontLightAxis.position.set(transform.x * aspect, transform.y * aspect, transform.z);

        fillLightBack.position.set(transform.x * aspect, transform.y * aspect + 8000, transform.z + 3000);
        fillLightLeft.position.set(transform.x * aspect + 3000, transform.y * aspect, transform.z - 3000);
        fillLightRight.position.set(transform.x * aspect - 3000, transform.y * aspect, transform.z - 3000);

        const r = 1000;

        if(USE_SHADOWS) {

            const EPS = 0.0001;
            if(Math.abs(frontLightDirection.x - frontLightDirection.y) < EPS && Math.abs(frontLightDirection.y  - frontLightDirection.z) < EPS && frontLightDirection.x <= 0) {
                finalLightPosition = new THREE.Vector3(transform.x, transform.y * aspect + 100, transform.z - 1000);
            } else {
                finalLightPosition = new THREE.Vector3(transform.x * aspect + r * frontLightDirection.x, transform.y * aspect + r * frontLightDirection.y, transform.z + r * frontLightDirection.z);
            }

        } else {

            finalLightPosition = new THREE.Vector3(transform.x, transform.y * aspect, transform.z - 1000);

        }

        oldPosition = framePosition.clone();
        oldRotations = frameRotations.clone();
        oldScale = transform.scale;
    }





}

const updateActiveCanvas = () => {


    const canvasActive    = activeCanvas
    const canvasInactive  = inactiveCanvas

    const cw              = canvasActive.width
    const ch              = canvasActive.height

    const ctxActive       = canvasActive.getContext("2d")
    const ctxInactive     = canvasInactive.getContext("2d")

    if(cameraMode == "user") {
        drawInputMirrored(ctxInactive, _width, _height, _webcam)
    } else {
        drawInput(ctxInactive, _width, _height, _webcam)
    }


    if(activeImageData === null) {

        activeImageData = ctxActive.getImageData(0, 0, cw, ch)
    }

    const imageActive     = activeImageData;
    const imageInactive   = ctxInactive.getImageData(0, 0, cw, ch)

    const imageDataActive   = imageActive.data
    const imageDataInactive = imageInactive.data

    const rowStep         = imageInactive.height / 6

    let foundDifference   = canvasActive === canvasInactive

    for(let row = rowStep; row < imageInactive.height && !foundDifference; row += rowStep) { // going through 4/5 rows

        const startIndex    = row * cw * 4 + 1
        const endIndex      = row * cw * 5 + 1

        for(let g = startIndex; g < endIndex; g += 4 ) {

            if(imageDataActive[g] !== imageDataInactive[g]) {

                foundDifference = true
                break
            }
        }
    }

    if(foundDifference) {

        let tmp           = activeCanvas
        activeCanvas      = inactiveCanvas
        inactiveCanvas    = tmp
        activeImageData   = imageInactive

        videoCanvasTexture.image = activeCanvas;
    }

    return foundDifference;
}


const updateEnvironment = () => {
    if(!_submeshes) return;
    updateEnvironmentMap(_submeshes, LightFuncs.environmentMap)
}


export {
    init,
    trackFace,
    updateColor,
    width,
    height,
    updateHeadphonesExtension,
    captureImageData,
    updateEnvironment,
    resetCamera,
    stopVideoFeed
}