import React from 'react';
import './intro.scss';

import Header from './header.js'
import { SystemUtils }                      from '../3d/utils/utils__system.js'


class Intro extends React.Component {

    constructor() {
        super();
        this.state ={
            isMounted: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isMounted: true,
            })
        }, 600);

        window.addEventListener("resize", this.onResize);
    }

    render() {

        const { isMounted } = this.state;
        const { nextStep, isActive, setColor } = this.props;

        return (
            isActive && <div className="intro">

                <Header />

                <div className={`intro__title__wrapper`}>
                    <h1 className={`intro__title ${isMounted ? 'intro__title--active' : ''}`}>SOLO PRO</h1>
                    <h2 className={`intro__title__2 ${isMounted ? 'intro__title__2--active' : ''}`}>VIRTUAL TRY - ON</h2>
                </div>


                {false && <div className="intro__ar__wrapper">
                    <div className="intro__ar__copy">See Solo Pro in AR</div>

                    <svg className="intro__ar__logo" width="14.316" height="16" viewBox="0 0 14.316 16">
                      <g id="Group_936" data-name="Group 936" transform="translate(-9.087 -8.095)">
                        <g id="noun_ar_2300882" transform="translate(9.087 8.095)">
                          <g id="Group_740" data-name="Group 740" transform="translate(0 0)">
                            <path id="Path_219" data-name="Path 219" d="M17.079,5.411a.419.419,0,0,1,.192.19l.006.012.012.028a.419.419,0,0,1,.027.157V8.737a.421.421,0,0,1-.842,0V6.471l-1.917.958a.421.421,0,1,1-.377-.753l1.773-.887L14.18,4.9a.421.421,0,1,1,.377-.753Zm.237,8.791a.421.421,0,0,1-.237.387L14.557,15.85a.421.421,0,0,1-.377-.753l1.773-.887-1.773-.887a.421.421,0,1,1,.377-.753l1.917.958V11.263a.421.421,0,1,1,.842,0Zm-12.953.008,1.773.887a.421.421,0,0,1-.377.753L3.237,14.589a.419.419,0,0,1-.192-.19l-.006-.012-.012-.028A.418.418,0,0,1,3,14.2V11.263a.421.421,0,1,1,.842,0v2.266l1.917-.958a.421.421,0,1,1,.377.753Zm-.52-7.74V8.737a.421.421,0,0,1-.842,0V5.8a.421.421,0,0,1,.237-.387L5.759,4.15a.421.421,0,0,1,.377.753l-1.773.887,1.773.887a.421.421,0,1,1-.377.753Zm6.12-4.423a.422.422,0,0,1,.392,0l2.519,1.259a.421.421,0,0,1-.377.753L10.579,3.1V5.368a.421.421,0,1,1-.842,0V3.1L7.82,4.061a.421.421,0,0,1-.377-.753Zm.392,15.9a.422.422,0,0,1-.392,0L7.443,16.692a.421.421,0,0,1,.377-.753l1.917.958V14.632a.421.421,0,0,1,.842,0V16.9l1.917-.958a.421.421,0,0,1,.377.753Zm.225-8.534v2.266a.421.421,0,0,1-.842,0V9.418L7.443,8.271a.421.421,0,0,1,.377-.753l2.338,1.169L12.5,7.518a.421.421,0,0,1,.377.753Z" transform="translate(-3 -2)" fill="#fff"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                </div>}

                <div className="step-controls">
                    <div><button onClick={nextStep}>Start</button></div>
                </div>
            </div>
        )
    }
}

export default Intro;