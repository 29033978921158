
import * as THREE from  'three'

function flatDeep(arr, d = 1) {
    return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
        : arr.slice();
};

const getAllSubmeshes = (m, arr = []) => {
    return flatDeep([...arr, m.children.length > 0 ? m.children.map(c => getAllSubmeshes(c)) : m], 10);
}

const updateEnvironmentMap = (submeshes, environmentTexture) => {

    submeshes.forEach(s => {
        const material = s.material;
        if(!material) return;

        if(material.envMap != null) {
            material.envMap = environmentTexture;
            material.needsUpdate = true;
        }
    })
}


const updateColorWay = (submeshes, colorWay, environmentTexture, currentView = "preview") => {
    const { color, metalColor, leatherColor, fabricColor, hingeColor } = colorWay;


    submeshes.forEach(s => {
        const material = s.material;
        if(!material) return;

        material.color.setStyle( color ) ;
        material.roughness = 0.7;
        material.metalness = 0.0;
        material.side = THREE.DoubleSide;

        switch (material.name) {

            //Top section of the headband
            case "plastic":
                material.color.setStyle( color ) ;
                material.roughness = currentView == "video" ? 0.65 : 0.5;
                if(!!environmentTexture) material.envMap = environmentTexture;
                material.metalness = 0.25;
                break;

            //fabric section of the headband
            case "soft_plastic":
                material.color.setStyle( color ) ;
                material.roughness = 0.9;
                material.metalness = 0.25;
                break;

            //Metal section of the headphones
            case "metal":
                material.color.setStyle( metalColor ) ;
                if(!!environmentTexture) material.envMap = environmentTexture;
                material.roughness = currentView == "video" ? 0.65 : 0.55;
                material.metalness = 0.8;
                break;

            case "logo_metal":
                material.color.setStyle( metalColor ) ;
                if(!!environmentTexture) material.envMap = environmentTexture;
                material.roughness = 0.5;
                material.metalness = 0.9;
                break;

            //Leather material
            case "leather":
                material.color.setStyle( leatherColor ) ;
                material.normalScale = new THREE.Vector2(4, 4);
                if(!!environmentTexture) material.envMap = environmentTexture;
                material.roughness = 0.7;
                material.metalness = 0.25;
                break;

            //Inner ear material
             case "fabric":
                 material.color.setStyle( fabricColor ) ;
                 material.roughness = 0.8;
                 material.metalness = 0.0;
                 material.normalScale = new THREE.Vector2(3, 3);
                break;

            //Hinge metal material
            case "hinge_r":
                material.color.setStyle( hingeColor ) ;
                if(!!environmentTexture) material.envMap = environmentTexture;
                material.envMapIntensity = 0.6;
                material.roughness = 0.7;
                material.metalness = 0.5;
                break;

            case "hinge_l":
                material.color.setStyle( hingeColor ) ;
                if(!!environmentTexture) material.envMap = environmentTexture;
                material.envMapIntensity = 0.6;
                material.roughness = 0.7;
                material.metalness = 0.5;
                break;

        }

        material.needsUpdate = true;
    })
}

export { updateColorWay, getAllSubmeshes, updateEnvironmentMap };