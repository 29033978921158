import React from "react";
import './prevButton.scss';


export default class PrevButton extends React.Component {

    render() {
        return (
            <div className = "prev-button">
                <svg className = "prev-button_icon" width="12.522" height="12.522" viewBox="0 0 12.522 12.522">
                    <path id="Path_236" data-name="Path 236" d="M7.855,0V7.855H0" transform="translate(12.522 6.261) rotate(135)" fill="none" stroke="rgba(255,255,255,1)" strokeWidth="2"/>
                </svg>
            </div>
        )
    }

}