
import React from 'react';
import './loaderUI.scss';

class LoaderUI extends React.Component {

    constructor(props) {
        super(props);
        this.loadCompleted = false;
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.loadReady && !this.loadCompleted) {
            this.loadCompleted = true;
            this.props.nextStep();
        }

    }

    render() {

        return (
            <div className="loader">

                <div className = "loader__logo_wrapper">
                    <svg className="loader__logo" width="76.203" height="76.206" viewBox="0 0 76.203 76.206">
                        <g id="Group_757" data-name="Group 757" transform="translate(-63.508 -320.343)">
                            <path d="M101.609,369.89a11.444,11.444,0,1,0-11.443-11.444A11.444,11.444,0,0,0,101.609,369.89Z" fill="#ffffff"/>
                            <path d="M101.609,320.343a38.163,38.163,0,0,0-11.1,1.647v19.968a19.851,19.851,0,1,1-8.917,16.564v-32.5a38.1,38.1,0,1,0,20.016-5.681Z" fill="#ffffff"/>
                        </g>
                    </svg>

                    <div className="loaderPercentWrapper">
                        <div className="loaderPercentTotal" />
                        <div className="loaderPercentLoaded" style = {{width: `${this.props.percentLoaded}%`}} />
                    </div>
                </div>

            </div>
        )
    }
}

export default LoaderUI;

