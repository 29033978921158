/*
Lighting functions are separated from the main arSetup since
it involves many shaders and separated functions that can be
called async from the main rendering function.
 */

import * as THREE from  'three'

let renderer,
    scene,
    camera,
    environmentMap,
    enviromentCanvas,
    enviromentCanvasCtx,
    videoCanvas,
    hsvMaterial,
    hsvPlane,
    videoCanvasTexture,
    pyramidBrightnessMaterial,
    pyramidNormalsMaterial,
    normalsLightMaterial,
    planeRenderTarget,
    headRenderTarget,
    debugFlag,
    videoPlane,
    testMaterial,
    normalsPlane,
    lightProxy,
    lightAxis,
    averagePlane,
    averageMaterial,
    headHSVMaterial;

let pyramidHSV = [];
let pyramidHSVScenes = [];
let pyramidHSVCameras = [];

let pyramidNormals = [];
let pyramidNormalsScenes = [];
let pyramidNormalsCameras = [];



//Setup shaders needed for the other functions
const setup = (_renderer, _scene, _camera, _videoCanvas, _lightProxy, _lightAxis, debug = true) => {

    renderer = _renderer;
    scene = _scene;
    camera = _camera;
    lightProxy = _lightProxy;
    lightAxis = _lightAxis;

    /*
    Configuration for the ambient texture, it uses the upper corner from the video feed to define what
    will be used as ambient cube texture in the headphones
     */
    videoCanvas = _videoCanvas;
    enviromentCanvas = document.createElement('canvas');
    enviromentCanvasCtx = enviromentCanvas.getContext('2d');
    enviromentCanvas.width = 128;
    enviromentCanvas.height = 128;
    environmentMap = new THREE.CubeTexture;
    environmentMap.images =  [enviromentCanvas, enviromentCanvas, enviromentCanvas, enviromentCanvas, enviromentCanvas, enviromentCanvas];
    updateAmbientTexture();



    /*
    Configuration of the brightness scene evaluation, this is used to module the intensity of the
    lights used in the scene
     */

    planeRenderTarget = new THREE.WebGLRenderTarget(512, 512, { minFilter: THREE.NearestFilter, magFilter: THREE.NearestFilter} );
    planeRenderTarget.texture.generateMipmaps = false;

    headRenderTarget = new THREE.WebGLRenderTarget(512, 512, { minFilter: THREE.NearestFilter, magFilter: THREE.NearestFilter} );
    headRenderTarget.texture.generateMipmaps = false;


    videoCanvasTexture = new THREE.CanvasTexture(videoCanvas);
    hsvMaterial = new THREE.ShaderMaterial({
        uniforms: {
            uTexture: { type: "t", value: videoCanvasTexture },
            uPower: {type: "f", value: 1}
        },
        vertexShader: vertexShader,
        fragmentShader: hsvShader,
        side: THREE.DoubleSide
    });

    headHSVMaterial = new THREE.ShaderMaterial(
        {
            uniforms: {
                normals: { type: "t", value: headRenderTarget.texture },
                hsv: { type: "t", value: planeRenderTarget.texture },
                scene: { type: "i", value: false }
            },
            vertexShader: vertexShader,
            fragmentShader: normalsHSVShader,
            side: THREE.DoubleSide
        }
    );

    pyramidBrightnessMaterial = new THREE.ShaderMaterial(
        {
            uniforms: {
                textureLevel: { type: "t", value: null},
                size: {value: 0}
            },
            vertexShader: vertexShader,
            fragmentShader: generatePyramidHSV,
            side: THREE.DoubleSide
        }
    )


    //Using 512 base texture so it should be 2^9
    for (let i = 0; i <= 9; i ++) {

        const size = Math.pow(2, i);
        pyramidHSV.push(new THREE.WebGLRenderTarget(size, size, { minFilter: THREE.NearestFilter, magFilter: THREE.NearestFilter } ));

        const sceneI = new THREE.Scene();
        sceneI.add(new THREE.Mesh( new THREE.PlaneBufferGeometry( size, size ), i == 9 ?  headHSVMaterial : pyramidBrightnessMaterial ));

        const cam = new THREE.OrthographicCamera( size / - 2, size / 2, size / 2, size / - 2, - 10000, 10000 );
        cam.position.z = 100;

        pyramidHSVScenes.push(sceneI);
        pyramidHSVCameras.push(cam);

    }


    //Used to test the brightness shader
    hsvPlane = new THREE.Mesh( new THREE.PlaneGeometry( 25, 25, 5 ), hsvMaterial);
    hsvPlane.position.z = 200;
    hsvPlane.position.x = 34;
    hsvPlane.position.y = 22;


    /*
     Configuration of main light scene position evaluation, this is used to define
     where the front light comes based on the lighting conditions on the face.
     */

    normalsLightMaterial = new THREE.ShaderMaterial(
        {
            uniforms: {
                normals: { type: "t", value: headRenderTarget.texture },
                hsv: { type: "t", value: planeRenderTarget.texture }
            },
            vertexShader: vertexShader,
            fragmentShader: normalsLightShader,
            side: THREE.DoubleSide
        }
    );

    pyramidNormalsMaterial = new THREE.ShaderMaterial(
        {
            uniforms: {
                textureLevel: { type: "t", value: null},
                size: {value: 0}
            },
            vertexShader: vertexShader,
            fragmentShader: generatePyramidNormals,
            side: THREE.DoubleSide
        }
    );

    for (let i = 0; i <= 9; i ++) {

        const size = Math.pow(2, i);
        pyramidNormals.push(new THREE.WebGLRenderTarget(size, size, { minFilter: THREE.NearestFilter, magFilter: THREE.NearestFilter } ));

        const sceneI = new THREE.Scene();
        sceneI.add(new THREE.Mesh( new THREE.PlaneBufferGeometry( size, size ), i == 9 ?  normalsLightMaterial : pyramidNormalsMaterial ));

        const cam = new THREE.OrthographicCamera( size / - 2, size / 2, size / 2, size / - 2, - 10000, 10000 );
        cam.position.z = 100;

        pyramidNormalsScenes.push(sceneI);
        pyramidNormalsCameras.push(cam);

    }

    videoPlane = new THREE.Mesh(new THREE.PlaneGeometry( 94, 71, 5 ), hsvMaterial );
    videoPlane.position.z = 200;


    /*
     Configuration for the test material used to check different textures.
     */

    testMaterial = new THREE.ShaderMaterial({
        uniforms: {
            texture: { type: "t", value: null }
        },
        vertexShader: vertexShader,
        fragmentShader: testTexture,
        side: THREE.DoubleSide
    });
    testMaterial.depthTest = false;


    //Used to test the brightness shader
    normalsPlane = new THREE.Mesh( new THREE.PlaneGeometry( 25, 25, 5 ), testMaterial);
    normalsPlane.position.z = 200;
    normalsPlane.position.x = -34;
    normalsPlane.position.y = 22;


    averageMaterial = testMaterial.clone();
    averagePlane = new THREE.Mesh( new THREE.PlaneGeometry( 25, 25, 5 ), averageMaterial);
    averagePlane.position.z = 200;
    averagePlane.position.x = -34;
    averagePlane.position.y = 0;


    debugMode(debug);

}





//Function used to debug the lighting results
const debugMode = status => {

    testMaterial.uniforms.texture.value = pyramidNormals[9];
    averageMaterial.uniforms.texture.value = pyramidHSV[8];

    debugFlag = status;
    if(status) {
        scene.add(hsvPlane);
        scene.add(normalsPlane)
        scene.add(lightProxy);
        scene.add(lightAxis);
        scene.add(averagePlane);

    } else {
        scene.remove(hsvPlane);
        scene.remove(normalsPlane);
        scene.remove(lightProxy);
        scene.remove(lightAxis);
        scene.remove(averagePlane);

    }
}





//Returns the average scene brightness to modulate the light
const updateSceneBrightness = (headMaterial, headphones) => {

    let brightness = {sceneBrightness: 0, faceBrightness: 0}

    //The head should be loaded and the material for the head defined.
    if(headMaterial !== null) {

        videoCanvasTexture.needsUpdate = true;

        //Don't display the headphones
        if (headphones !== null) headphones.visible = false;

        //Remove the debug planes
        let _debugFlag = debugFlag;
        debugMode(false);

        let w = renderer.domElement.width;
        let h = renderer.domElement.height;

        //Render the lighting information with a non linear mode inside a render target
        scene.add(videoPlane);
        renderer.setRenderTarget(planeRenderTarget);
        hsvMaterial.uniforms.uPower.value = 1;
        renderer.render(scene, camera);
        scene.remove(videoPlane);


        //Render the head with the normal information inside a render target
        headMaterial.colorWrite = true;
        renderer.setClearColor(0x000000, 1.0)
        renderer.setRenderTarget(headRenderTarget);
        renderer.render(scene, camera);
        headMaterial.colorWrite = _debugFlag;


        //Done two times to check the brightness of the face and the scene separated
        for (let j = 0; j < 2; j ++) {

            //Render the lighting information in the base of the pyramid
            headHSVMaterial.uniforms.scene.value = j == 0;

            renderer.setViewport(0, 0, 512, 512);
            renderer.setRenderTarget(pyramidHSV[9]);
            hsvMaterial.uniforms.uPower.value = 1;
            renderer.render(pyramidHSVScenes[9], pyramidHSVCameras[9]);


            //Generate the pyramid...
            for (let i = 0; i < 9; i++) {
                let size = Math.pow(2, 8 - i);
                renderer.setViewport(0, 0, size, size);
                renderer.setRenderTarget(pyramidHSV[8 - i]);

                pyramidBrightnessMaterial.uniforms.textureLevel.value = pyramidHSV[9 - i];
                pyramidBrightnessMaterial.uniforms.size.value = 512 / Math.pow(2, i + 1);

                renderer.render(pyramidHSVScenes[8 - i], pyramidHSVCameras[8 - i]);
            }


            let read = new Uint8Array( 4 );
            renderer.readRenderTargetPixels(pyramidHSV[0], 0, 0, 1, 1, read );

            ///Brightness comes in the range [0 - 1] with 255 levels.
            if(j == 0) brightness.sceneBrightness = read[0] / 256;
            else  brightness.faceBrightness = Math.pow(read[0] / 256, 0.5);

        }

        updateAmbientTexture();


        //Leave the renderer in the default state.
        debugMode(_debugFlag);
        renderer.setClearColor(0x000000, 0.0)
        if (headphones !== null) headphones.visible = true;
        renderer.setViewport(0, 0, w, h);
        renderer.setRenderTarget(null);

    }

    return brightness;
}






//Returns the average normal of section that is more illuminated from the face.
const updateMainFrontLightPosition = (headMaterial, headphones) => {

    let normal = new THREE.Vector3(0, 0, 0);

    //The head should be loaded and the material for the head defined.
    if(headMaterial !== null) {

        //Don't display the headphones
        if (headphones !== null) headphones.visible = false;


        //Remove the debug planes
        let _debugFlag = debugFlag;
        debugMode(false);


        //Save the previous size of the renderer
        let w = renderer.domElement.width;
        let h = renderer.domElement.height;


        //Render the lighting information with a non linear mode inside a render target
        scene.add(videoPlane);
//        hsvMaterial.uniforms.uPower.value = .1;
        renderer.setRenderTarget(planeRenderTarget);
        hsvMaterial.uniforms.uPower.value = 1;
        renderer.render(scene, camera);
        scene.remove(videoPlane);


        //Render the head with the normal information inside a render target
        headMaterial.colorWrite = true;
        renderer.setClearColor(0x000000, 1.0)
        renderer.setRenderTarget(headRenderTarget);
        renderer.render(scene, camera);
        headMaterial.colorWrite = _debugFlag;


        //Make the composition between normals and lighting information to define the normals
        //that represent the are with more light reflected.
        renderer.setViewport(0, 0, 512, 512);
        const id = pyramidNormals.length - 1;
        renderer.setRenderTarget(pyramidNormals[id]);
        renderer.clear();
        renderer.render(pyramidNormalsScenes[id], pyramidNormalsCameras[id]);

        //Generate the pyramid...
        for (let i = 0; i < 9; i++) {
            let size = Math.pow(2, 8 - i);
            renderer.setViewport(0, 0, size, size);
            renderer.setRenderTarget(pyramidNormals[8 - i]);

            pyramidNormalsMaterial.uniforms.textureLevel.value = pyramidNormals[9 - i];
            pyramidNormalsMaterial.uniforms.size.value = 512 / Math.pow(2, i + 1);

            renderer.render(pyramidNormalsScenes[8 - i], pyramidNormalsCameras[8 - i]);
        }

        let read = new Uint8Array(4);
        renderer.readRenderTargetPixels(pyramidNormals[0], 0, 0, 1, 1, read);

        normal = new THREE.Vector3(2 * read[0] / 256 - 1, 2 * read[1] / 256 - 1, 2 * read[2] / 256 - 1);
        normal.normalize();

        //Leave the renderer in the default state.
        debugMode(_debugFlag);
        renderer.setClearColor(0x000000, 0.0)
        if (headphones !== null) headphones.visible = true;
        renderer.setViewport(0, 0, w, h);
        renderer.setRenderTarget(null);

    }


    return normal;

}





//Updates the ambient light cube texture
const updateAmbientTexture = () => {
    enviromentCanvasCtx.drawImage(videoCanvas, 0, 0);
    environmentMap.needsUpdate = true;
}





/*
These shaders are used for the different calculations
 */

const vertexShader = `

        varying vec2 vUV;

        void main() {

            vUV = uv;

            vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * modelViewPosition;
        }
    `;


const hsvShader = `

        uniform sampler2D uTexture;
        uniform float uPower;
        varying vec2 vUV;

        vec3 rgb2hsv(vec3 c)
        {
            vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
            vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
            vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

            float d = q.x - min(q.w, q.y);
            float e = 1.0e-10;
            return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
        }

        void main() {

            vec2 st = vUV;
            st.x = 1. - vUV.x;

            vec3 rgbColor = texture2D(uTexture, st).rgb;

            //The idea is to check the value of each pixel, so it's "hsv" which makes me take the third option
            float value = rgb2hsv(rgbColor).b;
            gl_FragColor = vec4((vec3(1.) - pow(vec3(1. - value), vec3(uPower))), 1.);

        }
    `;


const generatePyramidHSV = `

    uniform sampler2D textureLevel;
    uniform float size;

    varying vec2 vUV;

    void main() {

        float k = 0.5 / size;
        vec2 position = floor(gl_FragCoord.xy) / size;

        vec4 a = texture2D(textureLevel,  position + vec2(0., 0.));
        vec4 b = texture2D(textureLevel,  position + vec2(k, 0.));
        vec4 c = texture2D(textureLevel,  position + vec2(0., k));
        vec4 d = texture2D(textureLevel,  position + vec2(k, k));

        float flagA = float(a.a > 0.);
        float flagB = float(b.a > 0.);
        float flagC = float(c.a > 0.);
        float flagD = float(d.a > 0.);

        //Calculate the average in the [-1, 1] space
        float  average = flagA * a.r +
                         flagB * b.r +
                         flagC * c.r +
                         flagD * d.r;

        float divider =  flagA + flagB + flagC + flagD;

        average /= max(divider, 1.);

        gl_FragColor =  gl_FragColor = vec4(vec3(average), 1.) * float(divider > 0.);

    }
    `;


const generatePyramidNormals = `

    uniform sampler2D textureLevel;
    uniform float size;

    varying vec2 vUV;

    void main() {

        float k = 0.5 / size;
        vec2 position = floor(gl_FragCoord.xy) / size;

        vec4 a = texture2D(textureLevel,  position + vec2(0., 0.));
        vec4 b = texture2D(textureLevel,  position + vec2(k, 0.));
        vec4 c = texture2D(textureLevel,  position + vec2(0., k));
        vec4 d = texture2D(textureLevel,  position + vec2(k, k));

        //Check if there's a normal in the texel
        float flagA = float(a.a > 0.);
        float flagB = float(b.a > 0.);
        float flagC = float(c.a > 0.);
        float flagD = float(d.a > 0.);

        //Calculate the average in the [-1, 1] space
        vec3 normalAverage = flagA * (2. * a.rgb - vec3(1.)) +
                             flagB * (2. * b.rgb - vec3(1.)) +
                             flagC * (2. * c.rgb - vec3(1.)) +
                             flagD * (2. * d.rgb - vec3(1.));

        float divider =  flagA + flagB + flagC + flagD;

        normalAverage = normalize(normalAverage);

        gl_FragColor = vec4(0.5 * normalAverage + vec3(0.5), a.a + b.a + c.a + d.a) * float(divider > 0.);

        if(size == 1. && gl_FragColor.a < 0.999) {
            gl_FragColor.rgb = vec3(0.5);
        }

    }
    `;


const normalsLightShader = `

    precision highp float;
    uniform sampler2D normals;
    uniform sampler2D hsv;

    varying vec2 vUV;

    void main() {

        vec2 uvFlip = vUV;
        uvFlip.x = 1. - uvFlip.x;
        vec3 normal = texture2D(normals, uvFlip).rgb;
        float value = texture2D(hsv, uvFlip).r;

        gl_FragColor = vec4(normal, 1. / 256.);

        //Renders if there's a normal and if the normal is highly illuminated
        float threshold = 0.9; //The threshold is used to define the normals that should be accounted for the lightihg.

        gl_FragColor *= step(threshold, value) * step(0.9, length(normalize(normal)));
    }
    `;


const normalsHSVShader = `

    precision highp float;
    uniform sampler2D normals;
    uniform sampler2D hsv;
    uniform bool scene;

    varying vec2 vUV;

    void main() {

        vec2 uvFlip = vUV;
        uvFlip.x = 1. - uvFlip.x;
        vec3 normal = texture2D(normals, uvFlip).rgb;
        float value = texture2D(hsv, uvFlip).r;

        gl_FragColor = vec4(vec3(value), 1.);

        if(scene) {

            //Used to evaluate brightness of the scene
            gl_FragColor *= float(normal.x == 0. && normal.y == 0. && normal.z == 0.);
        } else {
            //Used to evalate brightness of the face
            gl_FragColor *= step(0.9, length(normalize(normal)));

        }

    }
    `;


const testTexture = `

        uniform sampler2D texture;
        varying vec2 vUV;

        void main() {
            gl_FragColor = vec4(texture2D(texture, vUV).rgb, 1.);
        }
    `;



export {
    debugMode,
    setup,
    environmentMap,
    updateMainFrontLightPosition,
    updateAmbientTexture,
    updateSceneBrightness
    }