import React from 'react';
import * as THREE from 'three';
import {TweenLite, Power4, Power2} from 'gsap';
import GLTFLoader from '../3d/utils/GLTFLoader.js'
import {updateColorWay, getAllSubmeshes, updateEnvironmentMap} from '../3d/utils/colorUtils.js'

import Animator from '../3d/Animator.js'

import * as RotationController from "../3d/utils/rotationController.js"

import * as dat from 'dat.gui';
import './headphonePreview.scss'

const scale = 1;

class HeadphonePreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            backgroundGradientColor1: props.currentColor.backgroundGradientColor1,
            backgroundGradientColor2: props.currentColor.backgroundGradientColor2,
        };
    }

    componentDidMount() {


        //Environment map for the metal material
        this.enviromentCanvas = document.createElement('canvas');
        this.enviromentCanvasCtx = this.enviromentCanvas.getContext('2d');
        this.enviromentCanvas.width = 128;
        this.enviromentCanvas.height = 128;
        this.environmentMap = new THREE.CubeTexture;
        this.environmentMap.images =  [this.enviromentCanvas, this.enviromentCanvas, this.enviromentCanvas, this.enviromentCanvas, this.enviromentCanvas, this.enviromentCanvas];

        this.colorsConfig = this.props.colors;
        this.colorRequired = true;

        this.onResize = this.onResize.bind(this);

        this.introAnimationCompleted = false;

        this.scene = new THREE.Scene();
        this.camera  = new THREE.PerspectiveCamera ( 50, window.innerWidth/window.innerHeight, 0.1, 200 );

        var canvas = document.getElementById("headphone-preview");

        this.renderer  = new THREE.WebGLRenderer ({antialias: true,
                                                    alpha: true,
                                                    canvas: canvas,
                                                    precision: "highp",
                                                    powerPreference: "high-performance"
        });





        this.renderer.setClearColor( 0x000000, 0 );
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.renderer.physicallyCorrectLights = true;
        this.renderer.toneMappingExposure = 1;


        this.light = new THREE.AmbientLight( 0xffffff, 5 );
        this.scene.add( this.light );

        this.plight = new THREE.PointLight( 0xffffff, 0, 0, 2);
        this.plight.position.set( 0, 60, 0 );
        this.scene.add( this.plight );


        this.plight2 = new THREE.PointLight( 0xffffff, 0, 0, 2);
        this.plight2.position.set( -75, 30, 0 );
        this.scene.add( this.plight2 );


        this.plight3 = new THREE.PointLight( 0xffffff, 0, 0, 2);
        this.plight3.position.set( 0, 30, 45);
        this.scene.add( this.plight3 );


        this.plight4 = new THREE.PointLight( 0xffffff, 0, 0, 2);
        this.plight4.position.set( 0, -30, 45);
        this.scene.add( this.plight4 );


        this.container = new THREE.Object3D();
        this.scene.add(this.container);

        this.updateLights();

        this.animate = this.animate.bind(this);

        this.started = false;


        window.addEventListener('resize', this.onResize);
        this.onResize();

//        const gui = new dat.GUI();
//        console.log("colorConfig", this.colorsConfig)
//
//        gui.close();
//
//        Object.keys(this.colorsConfig).forEach(key => {
//            const color = this.colorsConfig[key];
//
//            var folder = gui.addFolder(color.name);
//
//            const ambientLightLevelController = folder.add(this.colorsConfig[color.name], 'ambientLightLevel', 0, 10);
//            ambientLightLevelController.onChange((value) => {
//                this.updateLights();
//            });
//
//            const lightLevelController = folder.add(this.colorsConfig[color.name], 'lightLevel', 0, 5000);
//            lightLevelController.onChange((value) => {
//                this.updateLights();
//            });
//
//            const colorController = folder.addColor(this.colorsConfig[color.name], 'color');
//            colorController.onChange((value) => {
//                this.setColor();
//            });
//
//            const metalColorController = folder.addColor(this.colorsConfig[color.name], 'metalColor');
//            metalColorController.onChange((value) => {
//                this.setColor();
//            });
//
//            const hingeColorController = folder.addColor(this.colorsConfig[color.name], 'hingeColor');
//            hingeColorController.onChange((value) => {
//                this.setColor();
//            });
//
//            const fabricColorController = folder.addColor(this.colorsConfig[color.name], 'fabricColor');
//            fabricColorController.onChange((value) => {
//                this.setColor();
//            });
//
//            const leatherColorController = folder.addColor(this.colorsConfig[color.name], 'leatherColor');
//            leatherColorController.onChange((value) => {
//                this.setColor();
//            });
//
//            const environmnetColorController = folder.addColor(this.colorsConfig[color.name], 'environmentColorReflection');
//            environmnetColorController.onChange((value) => {
//                this.setColor();
//            });
//
//        })
//
//        this.colorsConfig.save = () => {
//            alert(JSON.stringify(this.colorsConfig));
//        }
//        gui.add(this.colorsConfig, 'save');
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.step === "color") {
            this.setColorSelectPosition();
        }

        if(!!nextProps.currentColor) {
            this.setState({
                backgroundGradientColor1: this.colorsConfig[nextProps.currentColor.name].backgroundGradientColor1,
                backgroundGradientColor2: this.colorsConfig[nextProps.currentColor.name].backgroundGradientColor2,
            })
            this.setColor(nextProps.currentColor);
            this.updateLights(nextProps.currentColor);
        }

        if(!this.props.isActive && nextProps.isActive && this.introAnimationCompleted) {

            this.mesh.scale.set(scale, scale, scale);
            this.mesh.position.set(0, 0, 0);
            this.mesh.rotation.x = 0;
            this.mesh.visible = true;
            this.animator.setAnimation("unfold", 1, 1);
            this.animator.setAnimation("bend", 0, 1);
            this.animator.update();

            setTimeout( () => {
                this.enviromentCanvasCtx.fillStyle = this.props.currentColor.environmentColorReflection;
                this.enviromentCanvasCtx.fillRect(0, 0, 128, 128);
                this.environmentMap.needsUpdate = true;
                updateEnvironmentMap(this.submeshes, this.environmentMap);
                this.setColor(this.props.currentColor);
            }, 100);

            RotationController.resumeRotations(true);
        }

        if(nextProps.gltf != null  && !this.started && nextProps.loadReady) {

            this.started = true;

            this.mesh = nextProps.gltf.scene;

            this.submeshes = getAllSubmeshes(this.mesh);

            this.headphonesLight = new THREE.PointLight( 0x00ff00, 0, 100 )
            this.submeshes[this.submeshes.length - 1].add(this.headphonesLight);

            this.setColor(this.props.currentColor || {color: 0x000000})

            this.container.position.x = this.mesh.position.x;
            this.container.position.y = this.mesh.position.y;
            this.container.position.z = this.mesh.position.z;

            this.mesh.position.x = 0;
            this.mesh.position.y = 0;
            this.mesh.position.z = 0;

            this.container.add(this.mesh);

            this.animator = nextProps.gltf.animator;
            this.animationParams = {unfold: 0}
            this.animator.setAnimation("unfold", 0, 1);
            this.animator.update();


            this.setIntroPosition();
            this.playIntro();
            this.animate();
        }
    }

    animate() {
        requestAnimationFrame( this.animate );

        if(this.props.isActive) {
            this.container.add(this.mesh);
            this.animator.setAnimation("bend", 0, 0);
            this.animator.setAnimation("slide", 0, 0);
            RotationController.updateRotation();
            this.animator.update();
            this.renderer.render( this.scene, this.camera  );
        }

    };

    setColor(currentColor = this.props.currentColor) {

        if(!this.submeshes) return;

        currentColor = this.colorsConfig[currentColor.name] || currentColor;

        this.enviromentCanvasCtx.fillStyle = currentColor.environmentColorReflection;
        this.enviromentCanvasCtx.fillRect(0, 0, 128, 128);
        this.environmentMap.needsUpdate = true;

        if(this.props.isActive  || this.colorRequired) {
            this.colorRequired = false;
            updateColorWay(this.submeshes, currentColor, this.environmentMap, "preview");
        }
    }

    playIntro() {
        if(!this.mesh) return;

        //Run the animation
        TweenLite.to(this.animationParams, 1.4, {delay: 0.5, unfold: 1, ease: Power2.easeIn, onUpdate: ()=> {this.animator.setAnimation("unfold", this.animationParams.unfold)}});

        TweenLite.to(this.mesh.rotation, 3,
            {
                y: Math.PI / 6*1.8,
//                ease: Power4.easeOut,
                onUpdate: () => {
                    RotationController.updateRotation(this.mesh.rotation.y);
                },
                onStart: () => {
                    this.introAnimationCompleted = true;
                }
            }
        );
    }

    setIntroPosition() {
        if(!this.mesh) return;
        RotationController.setup(this.mesh, this.container);
        this.container.position.y = -1.2;
        this.mesh.rotation.y = 2 * Math.PI + Math.PI/ 6 * 1.8;
        RotationController.updateRotation(this.mesh.rotation.y);
        this.mesh.scale.set(scale, scale, scale);
    }

    setColorSelectPosition() {
        if(!(this.mesh && this.props.isActive)) return;

        TweenLite.to(this.mesh.rotation, 2, 
            {
                y: this.mesh.rotation.y - Math.PI,
                z: 0,
                ease: Power4.easeOut,
                onUpdate: () => {
                    RotationController.updateRotation(this.mesh.rotation.y);
                }
            }
        );
    }

    updateLights(currentColor = this.props.currentColor) {
        const color = this.colorsConfig[currentColor.name] || currentColor;
        this.light.intensity = color.ambientLightLevel || color.lightLevel;
        this.plight.intensity = color.lightLevel;
        this.plight2.intensity = color.lightLevel;
        this.plight3.intensity = color.lightLevel;
    }

    onResize() {
        const ww = window.innerHeight * 1.3;
        this.renderer.setSize(ww, window.innerHeight);
        const el = this.renderer.domElement;
        el.style.position = "absolute";
        el.style.top = "50%";
        el.style.left = "50%";
        el.style.transform = "translate(-50%, -50%)";
        this.camera.aspect = 1.3;
        this.camera.position.z = window.innerWidth < 800 ? 40: 35;
        this.camera.position.y = 7;
        this.camera.updateProjectionMatrix();
    }

    render() {
        const { backgroundGradientColor1, backgroundGradientColor2} = this.state;
        return (
            <div className="headphone-preview"
                style={{
                    background: backgroundGradientColor1 && `radial-gradient(ellipse at center, ${backgroundGradientColor2} 0%, ${backgroundGradientColor1} 70%)`,
                    transition: 'background .3s',
                }}
            >

                <canvas id="headphone-preview"></canvas>
            </div>
        )
    }
}

export default HeadphonePreview;