import * as THREE from "three";
import * as dat from 'dat.gui';

class Animator {
    
    constructor(obj, { guiControls }) {
        this.mixer;
        this.clips = {};
        this.addObject(obj);

        if(!!guiControls) {
            this.config = {};
            const gui = new dat.GUI();
            gui.close();
            Object.keys(this.clips).forEach(key => {
                const clip = this.clips[key];
                var folder = gui.addFolder(clip.name);
                
                this.config[clip.name] = {};
                this.config[clip.name].progress = 0.0;
                this.config[clip.name].weight = 0.0;
    
                folder.add(this.config[clip.name], 'progress', 0, 1);
                folder.add(this.config[clip.name], 'weight', 0, 1);
            })
        }
    }

    addObject(obj) {
        const clips = obj.animations;
        this.mixer = new THREE.AnimationMixer( obj.scene );
        clips.forEach(clip => {
            this.clips[clip.name] = {
                ...clip, 
                progress: 0,
                weight: 0,
                clipAction: this.mixer.clipAction(clip),
            };
            clip.enabled = true;
            this.clips[clip.name].clipAction.play();
        });
    }

    setAnimation(name, progress = 0, weight = 1) {
        if(name && this.clips[name]) {
            this.clips[name].progress = progress;
            this.clips[name].weight = weight;
        }
    }

    update() {
        if(!this.mixer) return;

        this.mixer.time=0;
        for(var i=0;i<this.mixer._actions.length;i++) {
            const clip = this.clips[this.mixer._actions[i]._clip.name];

            if(this.config && this.config[clip.name]) {
                this.mixer._actions[i].weight = this.config[clip.name].weight;
                this.mixer._actions[i].time = this.config[clip.name].progress * clip.duration;
            } else {
                this.mixer._actions[i].weight = clip.weight;
                this.mixer._actions[i].time = clip.progress * clip.duration;
            }
        }
        this.mixer.update(0)
    }
}

export default Animator;