import React from 'react';
import ColorPicker from '../shared/ColorPicker';
import './colorSelection.scss';

import PrevButton from '../shared/prevButton.js';
import Header from "./header"


class ColorSelection extends React.Component {

    render() {
        const { nextStep, previousStep } = this.props;
        return (
            <div>

                <Header previousStep = {previousStep} />

                <div className = "color-selector__label">SELECT YOUR COLOR</div>

                <div className = "color-selector__footer">
                    <div className = "color-selector__picker">
                        <ColorPicker {...this.props} />
                    </div>

                    <button className = "color-selector_next" onClick={nextStep}>next</button>

                </div>




            </div>
        )
    }
}

export default ColorSelection;