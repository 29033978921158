import React from 'react';
import './stepWrapper.scss';

class StepWrapper extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        const { children, isActive } = this.props;
        return (
            <div className={`step-wrapper ${isActive ? 'step-wrapper--active':''}`}>
                <div className={`step-container`}>
                    {children}
                </div>
            </div>
        )
    }
}

export default StepWrapper;