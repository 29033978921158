import React from 'react';
import './colorPicker.scss';

class ColorPicker extends React.Component {

    render() {

        const { colors, currentColor, setColor } = this.props;

        return (
            <div className={`color-picker`}>
                {!this.props.hideCopy && <p className={`color-picker__color__copy`}>{currentColor.copy}</p>}
                <div className="color-picker_selector">
                   {Object.keys(colors).map( key => {
                       const color = colors[key];
                       return color.name && <div
                        key={key}
                        className={`color-picker__color ${color.name === currentColor.name ? 'color-picker__color--active':''}`}
                        onClick={() => {setColor(color.name)}}
                       >
                            <div key={key} className={`color-picker__color__wrapper`}>
                                <div key={key} className={`color-picker__color__icon`}>
                                    <div key={key} className={`color-picker__color__icon__dot`}
                                        style={{
                                            backgroundColor: color.color,
                                        }}
                                    />
                                </div>
                            </div>
                       </div>
                    })}
                </div>
            </div>
        )
    }
}

export default ColorPicker;