import React from "react"

import "./header.scss"

import PrevButton from "../shared/prevButton.js";

export default class Header extends React.Component {

    render() {
        return (

            <div className = "header">

                {this.props.previousStep && <div className = "header__back" onClick = {this.props.previousStep}>
                    <svg width="12.522" height="12.522" viewBox="0 0 12.522 12.522">
                        <path id="Path_236" data-name="Path 236" d="M7.855,0V7.855H0" transform="translate(12.522 6.261) rotate(135)" fill="none" stroke="rgba(255,255,255,1)" strokeWidth="2"/>
                    </svg>
                </div>}

                <svg className="header__logo" width="76.203" height="76.206" viewBox="0 0 76.203 76.206">
                    <g id="Group_757" data-name="Group 757" transform="translate(-63.508 -320.343)">
                    <path d="M101.609,369.89a11.444,11.444,0,1,0-11.443-11.444A11.444,11.444,0,0,0,101.609,369.89Z" fill="#fff"/>
                    <path d="M101.609,320.343a38.163,38.163,0,0,0-11.1,1.647v19.968a19.851,19.851,0,1,1-8.917,16.564v-32.5a38.1,38.1,0,1,0,20.016-5.681Z" fill="#fff"/>
                    </g>
                </svg>

                <a className = "header__buy" href="https://www.beatsbydre.com/headphones/solo-pro" target="_blank" >BUY ON<span className = "appleLogo">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 23">
                        <g className="st0">
                            <path className="st1" fill="#ffffff" d="M9.6,6.9c0.7-0.2,1.3-0.3,1.7-0.3c0.8,0,1.5,0.2,2.1,0.6c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.2,0.3,0.3,0.5,0.6
                                c-0.5,0.5-0.9,0.9-1.1,1.2c-0.4,0.6-0.7,1.3-0.7,2.1c0,0.8,0.2,1.6,0.7,2.2c0.5,0.7,1,1.1,1.6,1.3c-0.1,0.4-0.3,0.8-0.5,1.2
                                c-0.2,0.4-0.4,0.8-0.7,1.3c-0.9,1.3-1.7,1.9-2.6,1.9c-0.4,0-0.8-0.1-1.4-0.3c-0.6-0.2-1.1-0.3-1.5-0.3c-0.4,0-0.9,0.1-1.4,0.3
                                c-0.6,0.2-1,0.3-1.3,0.3c-1,0-2-0.9-3-2.6c-1-1.7-1.5-3.4-1.5-5c0-1.5,0.4-2.8,1.1-3.7c0.8-1,1.7-1.4,2.9-1.4
                                c0.5,0,1.1,0.1,1.8,0.3c0.7,0.2,1.2,0.3,1.4,0.3C8.4,7.2,8.9,7.1,9.6,6.9z M10.9,4.6c-0.2,0.5-0.5,1-0.9,1.4
                                C9.6,6.3,9.2,6.6,8.9,6.7c-0.3,0.1-0.6,0.1-1,0.2c0-1,0.3-1.8,0.8-2.6C9.2,3.5,10,3,11.2,2.8c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
                                C11.2,3.6,11.1,4.1,10.9,4.6z"/>
                        </g>
                    </svg>
                </span></a>

            </div>

            )
    }

}