import React from "react"
import './faceView.scss'
import ColorPicker from '../shared/ColorPicker';
import ColorPickerVertical from '../shared/ColorPickerVertical';
import Header from "./header"
import { SystemUtils }                      from '../3d/utils/utils__system.js'
import * as faceAR from "../3d/arSetup.js"
import PrevButton from '../shared/prevButton.js';


import watermarkImage  from "../assets/3d/headphones/soloProLogoLabel.png";
import watermarkImage2X  from "../assets/3d/headphones/soloProLogoLabel2x.png";

class FaceView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            faceFail: false,
            captureImage: false,
            adjustColor: false,
            verticalOrientation: true,
            saveMode: false,
            instructionsReady: false,
            cameraNonAvailable: false
        }

        this.timeout = 0;
        this.overlayFired = false;
        this.currentColor = props.currentColor;
        this.tipsDelay = 2000;
    }

    componentDidMount() {
        this.faceCanvas = document.querySelector("#_imageData");
        this.faceCanvas2 = document.querySelector("#_imageData2");

        this.canvas3D = document.querySelector("#_3dData");
        window.addEventListener("resize", this.onResize);

        this.slider = document.querySelector(".slider");
        this.sliderWrapper = document.querySelector(".sliderWrapper");

        this.sliderWrapper.addEventListener("mousedown", this.startDrag);
        this.sliderWrapper.addEventListener("touchstart", this.startDrag);

        this.sliderAmount = document.querySelector(".sliderAmount");

        document.body.addEventListener("mouseup", this.stopDrag);
        document.body.addEventListener("touchend", this.stopDrag);

        //Functions used to reset the tips
        document.body.addEventListener("mousedown", this.resetTips);
        document.body.addEventListener("touchstart", this.resetTips);



        this.onResize();
    }

    resetTips = () => {
        if(this.overlayFired) {
            this.overlayFired = false;
            clearTimeout(this.timeout);
            this.setState({faceFail: false});
        }
    }

    startDrag = e => {
        document.body.addEventListener("mousemove", this.updateSliderDesktop);
        document.body.addEventListener("touchmove", this.updateSliderMobile);
        this.topSlide = document.querySelector(".sliderGuide").getBoundingClientRect().top;
    }

    stopDrag = e => {
        document.body.removeEventListener("mousemove", this.updateSliderDesktop);
        document.body.removeEventListener("touchmove", this.updateSliderMobile);
    }

    updateSliderMobile = e => {
        this.updateSlider(e.touches[0].pageY);
    }

    updateSliderDesktop = e => {
        this.updateSlider( e.clientY);
    }

    updateSlider = val => {

        this.resetTips();

        let relative = val - this.topSlide;
        relative = Math.min(Math.max(0, relative), 140);
        this.slider.style.transform = `translate(-8px, ${relative}px)`;

        this.sliderAmount.style.height = `${140 - relative}px`;

        //normalize from 0 to 1
        relative /= 140;

        faceAR.updateHeadphonesExtension(1 - relative);

    }

    componentWillReceiveProps(nextProps) {

        if(!this.props.isActive && nextProps.isActive && this.isActivated) {
            faceAR.updateEnvironment();
            faceAR.updateColor(nextProps.currentColor);
            faceAR.resetCamera();
        }
        
        if(!nextProps.isActive && this.props.isActive && this.isActivated) {
            faceAR.stopVideoFeed();
        }

        if(nextProps.isActive && !this.isActivated) {
            this.isActivated = true;

            faceAR.init(nextProps.gltf, nextProps.occlusionMesh, nextProps.brfv5Manager, nextProps.brfv5Config, (value) => {

                if(this.state.instructionsReady) {
                    if(value && !this.state.faceFail && !this.overlayFired) {

                        this.overlayFired = true;

                        this.timeout = setTimeout(() => {
                            this.setState({faceFail: value})
                        }, this.tipsDelay);

                    }

                    if(!value && this.state.faceFail) {
                        this.overlayFired = false;
                        clearTimeout(this.timeout);
                        this.setState({faceFail: value})
                    }
                }
            }).then(() => {
                faceAR.updateEnvironment();
                faceAR.updateColor(nextProps.currentColor);
            }).catch(() => {
                console.log("error with the camera");
                this.setState({cameraNonAvailable: true})
            })

            this.onResize();
            this.updateFaceTracking();
        }

        if(this.props.isActive && this.isActivated && nextProps.currentColor && nextProps.currentColor != this.currentColor) {
            this.currentColor = nextProps.currentColor;
            faceAR.updateColor(nextProps.currentColor);
        }
    }

    updateFaceTracking = () => {
        requestAnimationFrame(this.updateFaceTracking);

        if(this.props.isActive && !this.state.captureImage) {
            faceAR.trackFace();
        }
    }

    onResize = () => {

        const ww = window.innerWidth;
        const wh = window.innerHeight;

        if(ww <= 600) {
            if(wh > ww) {
                this.faceCanvas.style.height = wh;
                this.faceCanvas.style.width = "auto";

                this.faceCanvas2.style.height = wh;
                this.faceCanvas2.style.width = "auto";

                this.canvas3D.style.height = wh;
                this.canvas3D.style.width = "auto";

            } else {
                this.faceCanvas.style.width = ww;
                this.faceCanvas.style.height = "auto";

                this.faceCanvas2.style.width = ww;
                this.faceCanvas2.style.height = "auto";

                this.canvas3D.style.width = ww;
                this.canvas3D.style.height = "auto";

            }
        } else {

            this.faceCanvas.style.width = null;
            this.faceCanvas.style.height = null;

            this.faceCanvas2.style.width = null;
            this.faceCanvas2.style.height = null;

            this.canvas3D.style.height = null;
            this.canvas3D.style.width = null;

        }


        //Check the orientation for the phone screen
        if(SystemUtils.isMobileOS) {
            const orientation = window.matchMedia("(orientation: portrait)");
            console.log(orientation);
            this.setState({verticalOrientation: orientation.matches});
        }


    }

    captureImage = () => {
        this.setState({captureImage: true});
    }


    saveImage = () => {

        this.imageData = faceAR.captureImageData();

        //Make the composition of the 3d render view and the watermark logo image.
        let canvas = document.createElement("canvas");
        canvas.width = this.imageData.width;
        canvas.height = this.imageData.height;

        canvas.style.position = "absolute";
        canvas.style.top = "0px";
        canvas.style.left = "0px";

        let ctx = canvas.getContext("2d");

        let image = new Image();

        image.onload = () => {
            ctx.drawImage(image, 0, 0);

            let watermark = new Image();

            watermark.onload = () => {

                ctx.drawImage(watermark, 0.5 * (canvas.width - 350), 0.83 * canvas.height);

                if(SystemUtils.isMobileOS) {

                    let imageToSave = new Image();

                    imageToSave.onload = () => {
                        this.setState({saveMode: true})
                        const node = document.querySelector(".saveImage-image");
                        node.innerHTML = '';
                        node.appendChild(imageToSave);
                    }

                    imageToSave.src = canvas.toDataURL();


                } else {

                    let link = document.createElement("a");
                    link.download = 'beats_solo_pro.png';
                    link.href = canvas.toDataURL();
                    link.click();
                    this.setState({captureImage: false});

                }
            }

            watermark.src = watermarkImage2X;
        }

        image.src = this.imageData.base64;
    }

    retakeImage = () => {
        this.setState({captureImage: false});
    }

    selectOption = (status) => {

        this.setState({adjustColor: status})
    }

    render() {
        const { previousStep } = this.props;

        const saveImageLabel = SystemUtils.isMobileOS ? "SAVE" : "DOWNLOAD";

        return (
            <div>

                <div className = "backgroundCanvasWrapper">
                    <canvas id = "_backgroundCanvas" />
                </div>

                <div id = "faceARWrapper" className={"__brfv5"}>
                    <video  id="_webcam" playsInline></video>
                    <canvas id="_imageData"></canvas>
                    <canvas id="_imageData2"></canvas>
                    <canvas id="_3dData"></canvas>

                    {this.state.captureImage && <img className = "watermark" src = {watermarkImage} />}

                </div>

                {this.state.cameraNonAvailable && <div className = "faceTracking_noCamera">Camera not available. On iPhone and iPad devices please visit the site using Safari.</div>}

                <div className = {`faceTracking_instructions ${this.state.instructionsReady || this.state.cameraNonAvailable? "" : "faceTracking_instructions--active"}`}>

                    <div className = "faceTracking_instructions_color">
                        <svg width="17.908" height="231" viewBox="0 0 17.908 231">
                          <g id="Group_955" data-name="Group 955" transform="translate(-38.592 -311.5)">
                            <line id="Line_52" data-name="Line 52" x2="8" transform="translate(48.5 426.5)" fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="1" opacity="0.5"/>
                            <path id="Path_265" data-name="Path 265" stroke="#fff" d="M0,0H10V230H0" fill="none" transform="translate(38.592 312)" strokeLinejoin="round" strokeWidth="1" strokeDasharray="1" opacity="0.5"/>
                          </g>
                        </svg>
                        <div className = "faceTracking_instructions_color_copy">
                            <div className = "faceTracking_instructions_copy_title">CHOOSE COLOR</div>
                            <div className = "faceTracking_instructions_copy_label">Choose your favorite color to try on</div>
                        </div>
                    </div>

                    <div className = "faceTracking_instructions_label">How To Use</div>

                    <div className = 'faceTracking_instructions_slider'>
                        <svg width="17.5" height="248" viewBox="0 0 17.5 248">
                          <g id="Group_956" data-name="Group 956" transform="translate(-322.5 -359.639)">
                            <line id="Line_53" data-name="Line 53" x2="8" transform="translate(322.5 477.011)" fill="none" stroke="#fff" strokeWidth="1" strokeDasharray="1" opacity="0.5"/>
                            <path id="Path_266" data-name="Path 266" d="M0,0H10V247H0" transform="translate(340 607.139) rotate(180)" fill="none" stroke="#707070" strokeLinejoin="round" strokeWidth="1" strokeDasharray="1"/>
                          </g>
                        </svg>
                        <div className = "faceTracking_instructions_slider_copy">
                            <div className = "faceTracking_instructions_copy_title">ADJUST FIT</div>
                            <div className = "faceTracking_instructions_copy_label">Adjust your fit by using the slider</div>
                        </div>
                    </div>

                    <div onClick = {() => {this.setState({instructionsReady: true})}} className = "faceTracking_instructions_button">
                        <button>GOT IT</button>
                    </div>

                </div>


                <div className = {`faceTracking_controls ${this.state.captureImage || this.state.cameraNonAvailable? "" : "faceTracking_controls--active"}`}>

                    <div className = "sliderWrapper">
    
                        <svg width="41" height="250" viewBox="0 0 41 250">
                          <g id="Group_990" data-name="Group 990" transform="translate(-994 -281)">
                            <g id="Rectangle_523" data-name="Rectangle 523" transform="translate(995 282)" fill="rgba(0,0,0,0.5)" stroke="rgba(255,255,255,0.2)" strokeWidth="1">
                              <rect width="39" height="248" rx="8" stroke="none"/>
                              <rect x="-0.5" y="-0.5" width="40" height="249" rx="8.5" fill="none"/>
                            </g>
                            <g id="Group_989" data-name="Group 989" transform="translate(658.5 -0.092)">
                              <path id="Path_271" data-name="Path 271" d="M160.795,0" transform="translate(356 483.795) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" opacity="0.3"/>
                              <g id="Group_986" data-name="Group 986" transform="translate(359.514 502.708) rotate(90)">
                                <path id="Path_270" data-name="Path 270" d="M4.258,0l.125,4.383L0,4.258" transform="translate(0.686 3.1) rotate(-45)" fill="none" stroke="#fff" strokeWidth="1"/>
                                <line id="Line_55" data-name="Line 55" x1="6.967" transform="translate(0 3.166)" fill="none" stroke="#fff" strokeWidth="1"/>
                              </g>
                              <g id="Group_985" data-name="Group 985" transform="translate(352.884 308.675) rotate(-90)">
                                <path id="Path_270-2" data-name="Path 270" d="M4.258,0l.125,4.383L0,4.258" transform="translate(0.686 3.1) rotate(-45)" fill="none" stroke="#fff" strokeWidth="1"/>
                                <line id="Line_55-2" data-name="Line 55" x1="6.966" transform="translate(0 3.166)" fill="none" stroke="#fff" strokeWidth="1"/>
                              </g>
                            </g>
                          </g>
                        </svg>

                        <div className = "sliderGuide">
                            <div className = "sliderAmount" />
                            <div className = "slider" />
                        </div>
                    </div>

                    <div className = "faceTracking-color-selector__picker">
                        <ColorPickerVertical {...this.props} hideCopy = {true} />
                    </div>

                    {this.state.instructionsReady && <button className = "faceTracking-capture" onClick = {this.captureImage}>CAPTURE</button>}

                </div>

                <div className = {`faceTracking-capture-wrapper ${this.state.captureImage ? "faceTracking-capture-wrapper--active" : ""}`}>
                    <div className = "faceTracking-capture-save" onClick = {this.saveImage}>{saveImageLabel}</div>
                    <div className = "faceTracking-capture-retake" onClick = {this.retakeImage}>RETAKE</div>
                </div>}


                <div className = {`tips  ${!!this.state.faceFail ? " tips--active" : ""}`}>
                    <div className = "tips-wrapper">
                        <p className = "tips-title">Looks Like It's Too Dark</p>
                        <p className = "tips-item">Find a well lit aread and try again</p>
                        <button>GOT IT</button>
                    </div>
                </div>


                <div className = {`orientation  ${!this.state.verticalOrientation ? " orientation--active" : ""}`}>
                    <div className = "orientation-wrapper">
                        <p className = "orientation-title">Horizontal!</p>
                        <p className = "orientation-item">Virtual Try On only works in portrait...</p>
                    </div>
                </div>


                <div className = {`saveImage  ${this.state.saveMode ? " saveImage--active" : ""}`}>
                    <div className = "saveImage-wrapper">
                        <div className = "saveImage-image" />
                        <div className = "saveImage-label">Tap and Hold to Download</div>
                        <div className = "saveImage-retake" onClick = {() => {faceAR.resetCamera();
                                                                              this.setState({saveMode: false, captureImage: false})}}>RETAKE</div>
                    </div>
                </div>


                <div className = "headerWrapper">
                    <Header previousStep = {previousStep}/>
                </div>

            </div>
        )
    }
}

export default FaceView;