import {TweenLite} from 'gsap';


let down,
    prevPosX,
    currentPosX,
    prevPosY,
    currentPosY,
    beta,
    _beta,
    alpha,
    _alpha,
    init,
    mesh,
    speedX,
    speedY,
    decayX,
    decayY,
    container,
    enabled;

const MAX_ALPHA_ANGLE = 20 * Math.PI / 180;

const setup = (_mesh, _container) => {
    down = false;
    prevPosX = 0;
    currentPosX = 0;
    beta = 0;
    _beta = beta;
    alpha = _alpha = 0;
    mesh = _mesh;
    container = _container;
    speedX = 0.220;
    speedY = 0.030;
    decayX = 12;
    decayY = 8;

    init = true;
    enabled = false;

    resumeRotations(false);

}

const aa = e => {
    currentPosX = e.clientX;
    currentPosY = e.clientY;
}

const bb = e => {
    document.body.style.cursor = "-moz-grabbing";
    document.body.style.cursor = " -webkit-grabbing";
    down = true;
}

const cc = e => {
    document.body.style.cursor = "grab";
    document.body.style.cursor = "grab";
    down = false;
}

const dd = e => {
    e.preventDefault();
    currentPosX = e.touches[0].pageX;
    currentPosY = e.touches[0].pageY;
}

const ee = e => {
    currentPosX = e.touches[0].pageX;
    currentPosY = e.touches[0].pageY;
    prevPosX = currentPosX;
    prevPosY = currentPosY;
    speedX = 0.80;
    speedY = 0.25;
    decayX = 7;
    decayY = 7;
    down = true;
}

const resumeRotations = (reset = true) => {

    if(!enabled) {
        document.body.style.cursor = "grab";
        document.body.style.cursor = "grab";

        //For the mouse
        document.addEventListener('mousemove', aa);
        document.addEventListener('mousedown', bb);
        document.addEventListener('mouseup', cc);


        //For the touch events
        document.addEventListener('touchmove', dd);
        document.addEventListener('touchstart', ee);
        document.addEventListener('touchend', cc);


        if(reset) {
            _beta = beta = 0;
            mesh.rotation.y = 0;
        }
        enabled = true;
    }
}



const stopRotations = () => {

    if(enabled) {
        document.body.style.cursor = "auto";
        document.body.style.cursor = "auto";


        document.removeEventListener('mousemove', aa);
        document.removeEventListener('mousedown', bb);
        document.removeEventListener('mouseup', cc);


        //For the touch events
        document.removeEventListener('touchmove', dd);
        document.removeEventListener('touchstart', ee);
        document.removeEventListener('touchend', cc);

        mesh.rotation.y = 0;

        enabled = false;
    }

}


const updateRotation = (value = null) => {

    if(value) {

        _beta = value;
        beta = value;

        if(down) TweenLite.killTweensOf(mesh.rotation);

    } else {
        if (down) {
            beta += speedX * (currentPosX - prevPosX) * Math.PI / 180;
            alpha += speedY * (currentPosY - prevPosY) * Math.PI / 180;

            if (alpha <= -MAX_ALPHA_ANGLE) alpha = -MAX_ALPHA_ANGLE;
            if (alpha >= MAX_ALPHA_ANGLE) alpha = MAX_ALPHA_ANGLE;
        }

        if (_alpha != alpha || _beta != beta || init) {
            _beta += (beta - _beta) / decayX;
            _alpha += (alpha - _alpha) / decayY;
            mesh.rotation.y = _beta;
            container.rotation.x = _alpha;
        }

        prevPosX = currentPosX;
        prevPosY = currentPosY;
    }


}

export {
    setup, updateRotation, stopRotations, resumeRotations
    }