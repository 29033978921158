import React from "react"

const Context = React.createContext();

const STEPS = [
    "loader",
    "intro",
    "color",
    "try on",
    "share",
]

const COLORS = {
    lightBlue: {
        name: 'lightBlue',
        copy: 'More Matte Collection - Light Blue',
        color:  "#51aded",
        leatherColor:  "#57b5f7",
        metalColor: "#4ddfff",
        fabricColor: "#4591b9",
        hingeColor: "#ffffff",
        environmentColorReflection: '#60b7f5',
        ambientLightLevel: 2.7,
        lightLevel: 1500,
        backgroundGradientColor1: "#467fa3",
        backgroundGradientColor2: "#58a0cd",
    },
    red: {
        name: 'red',
        copy: 'More Matte Collection - Red',
        color: "#f03b2f",
        leatherColor: "#f03b2f",
        metalColor: "#f23826",
        fabricColor: "#d23c2d",
        hingeColor: "#ffffff",
        environmentColorReflection: '#df4b3c',
        ambientLightLevel: 2,
        lightLevel: 4000,
        backgroundGradientColor1: "#aa3a2e",
        backgroundGradientColor2: "#df4b3c",
    },
    darkBlue: {
        name: 'darkBlue',
        copy: 'More Matte Collection - Dark Blue',
        color: "#16194a",
        leatherColor: "#16194a",
        metalColor: "#6e85f0",
        hingeColor: "#d2d2d2",
        fabricColor: "#11133c",
        environmentColorReflection: '#222648',
        lightLevel: 3000,
        ambientLightLevel: 2,
        backgroundGradientColor1: "#202242",
        backgroundGradientColor2: "#222648"
    },
    ivory: {
        name: 'ivory',
        copy: 'Ivory',
        color:  "#e3dbd6",
        leatherColor:  "#e8e1dd",
        metalColor: "#fad4ab",
        hingeColor: "#fad4ab",
        fabricColor: "#decfc6",
        environmentColorReflection: '#ffffff',
        ambientLightLevel: 2.9,
        lightLevel: 1250,
        backgroundGradientColor1: "#e4deda",
        backgroundGradientColor2: "#e4deda"
    },
    black: {
        name: 'black',
        copy: 'Black',
        color: "#141414",
        leatherColor: "#141414",
        metalColor: "#666666",
        hingeColor: "#202020",
        fabricColor: "#111111",
        environmentColorReflection: '#4d4d4d',
        lightLevel: 3000,
        ambientLightLevel: 3.2,
    },
    gray: {
        name: 'gray',
        copy: 'Gray',
        color:  "#a39693",
        leatherColor:  "#a39693",
        metalColor: "#b3937b",
        hingeColor: "#986f51",
        fabricColor: "#8c7a77",
        environmentColorReflection: '#ffffff',
        ambientLightLevel: 2.7,
        lightLevel: 2000,
        backgroundGradientColor1: "#aba4a2",
        backgroundGradientColor2: "#b9b2af",
    },
}

class Provider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: STEPS[0],
            currentColor: COLORS.black,
        }

        this.nextStep = this.nextStep.bind(this);
        this.previousStep = this.previousStep.bind(this);
        this.setColor = this.setColor.bind(this);
    }

    nextStep() {
        this.setState(state => ({
            step: STEPS[STEPS.indexOf(state.step) + 1],
        }));
    }

    previousStep() {

        const status = STEPS[STEPS.indexOf(this.state.step) - 1];

        if(status == "intro") {

            this.setState({
                currentColor: COLORS.black
            })
        }

        this.setState(state => ({
            step: status
        }));

    }

    setColor(color) {

        if(!COLORS[color] || this.state.currentColor.name === color) return;

        this.setState({
            currentColor: COLORS[color]
        })
    }

    render() {

        const { children } = this.props;

        return (
            <Context.Provider value={{
                nextStep: this.nextStep,
                previousStep: this.previousStep,
                setColor: this.setColor,
                ...this.state,
                colors: COLORS,
            }}>
                {children}
            </Context.Provider>
        )
    }
}

export {Context, Provider};