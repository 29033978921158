import React from "react"
import './app.scss'
import {Context} from "./context/AppContext"
import Intro from "./components/Intro"
import ColorSelection from "./components/ColorSelection"
import FaceView from "./components/FaceView"
import StepWrapper from "./shared/StepWrapper"
import HeadphonePreview from "./components/HeadphonePreview"
import LoaderUI from "./components/loaderUI"

import {loadBRFv5Model }    from './3d/brfv5/brfv5__init.js'
import GLTFLoader from './3d/utils/GLTFLoader.js'
import FBXLoader from 'three-fbxloader-offical';

import Animator from './3d/Animator.js'

import {updateColorWay, getAllSubmeshes} from './3d/utils/colorUtils.js'



import * as THREE                   from  'three'

//-- Steps
// - intro (splash screen)
// - color selection
// - accept camera
// - face view
// - share

const COMPONENTS = [
    {
        component: LoaderUI,
        activeSteps: ["loader"]
    },
    {
        component: HeadphonePreview,
        activeSteps: ["intro", "color"]
    },
    {
        component: FaceView,
        activeSteps: ["try on"]
    },
    {
        component: ColorSelection,
        activeSteps: ["color"]
    },
    {
        component: Intro,
        activeSteps: ["intro"]
    }

];

//The models should be in the public folder
const MODEL_URL = './models/';
const _appId          = 'brfv5.browser.minimal.modules';


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loadReady: false, percentLoaded: 0, gltf: null, aoTexture: null, normalTexture: null}

        this.occlusionMesh = null;
        this.brfv5Manager = null;
        this.brfv5Config = null;

        this.headphonesBytesTotal = 0;
        this.headphonesBytesLoaded = 0;
        this.headphonesLoaded = false;

        this.headBytesTotal = 0;
        this.headBytesLoaded = 0;
        this.headLoaded = false;

        this.modelBytesTotal = 0;
        this.modelBytesLoaded = 0;
        this.modelLoaded = false;

        this.percentLoaded = 0;

        this.headphonesTexturesUpdated = false;


        //The total has to be hardcoded since the server is not providing the total size of the files to load.
        this.total = 190436 + 552338 + 2453557;


        //Load the headphones mesh
        const gltfLoader = new GLTFLoader();
        gltfLoader.load("./assets/3d/headphones/beatsHeadPhones_v016.gltf", (gltf => {


            const aoImage = new Image();
            const normalImage = new Image();

            gltf.animator = new Animator(gltf, { guiControls: false });

            aoImage.onload = () => {

                normalImage.onload = ()=> {

                    const mesh = gltf.scene;
                    const submeshes = getAllSubmeshes(mesh);

                    const aoTexture = new THREE.Texture();
                    aoTexture.magFilter = THREE.LinearFilter;
                    aoTexture.minFilter = THREE.LinearMipmapLinearFilter;
                    aoTexture.generateMipmaps = true;

                    const normalTexture = new THREE.Texture();

                    aoTexture.image = aoImage;
                    normalTexture.image = normalImage;

                    aoTexture.flipY = false;
                    normalTexture.flipY = false;

                    aoTexture.needsUpdate = true;
                    normalTexture.needsUpate = true;


                    for(let m of submeshes) {
                        if(m.material) {
                            m.material.map = aoTexture;
                            m.material.mapIntensity = 1;
                            m.material.map.needsUpdate = true;
                            m.material.normalMap = normalTexture;
                            m.material.normalScale = new THREE.Vector2(7, 7);
                            m.material.normalMap.needsUpdate = true;
                            m.material.side = THREE.DoubleSide;
                            m.material.shadowSide = THREE.BackSide;
                            m.material.needsUpdate = true;
                        }
                    }

                }

                normalImage.src = "./assets/3d/headphones/beats_Normal3.png";
            }

            aoImage.src = "./assets/3d/headphones/beats_AmbientOcclusion.png";

            this.headphonesLoaded = true;
            this.checkLoadStatus();

            this.setState({gltf:gltf});


        }
        ), (e) => {
            this.headphonesBytesLoaded = e.loaded;
            this.headphonesBytesTotal = e.total; //<<---- this is not working on server

            const percent = Math.min(100, Math.ceil(100 * (this.headBytesLoaded + this.headphonesBytesLoaded + this.modelBytesLoaded) / this.total));
            this.setState({percentLoaded: percent});
        })


        //Load the head occlusion mesh
        const loader = new THREE.FBXLoader();
        loader.load('./assets/3d/model_noEars_LowerHead.fbx', obj => {

            this.occlusionMesh = obj;

            this.headLoaded = true;
            this.checkLoadStatus();

        }, (e) => {
            this.headBytesLoaded = e.loaded;
            this.headBytesTotal = e.total; //<<---- this is not working on server

            const percent = Math.min(100, Math.ceil(100 * (this.headBytesLoaded + this.headphonesBytesLoaded + this.modelBytesLoaded) / this.total));
            this.setState({percentLoaded: percent});
        })


        //Load the BRFV5 model
        loadBRFv5Model('42l_min', MODEL_URL, _appId, e => {

            this.modelBytesLoaded = e.loaded;
            this.modelBytesTotal = e.total; //<<---- this is not working on server

            const percent = Math.min(100, Math.ceil(100 * (this.headBytesLoaded + this.headphonesBytesLoaded + this.modelBytesLoaded) / this.total));
            this.setState({percentLoaded: percent});

        }).then(({ brfv5Manager, brfv5Config }) => {

            this.modelLoaded = true;
            this.checkLoadStatus();

            this.brfv5Manager = brfv5Manager;
            this.brfv5Config = brfv5Config;
        })

    }

    checkLoadStatus() {
        if(this.modelLoaded && this.headLoaded && this.headphonesLoaded) this.setState({loadReady: true})
    }

    componentDidMount() {

        document.addEventListener('touchmove', function (event) {
            if (event.scale !== 1) { event.preventDefault(); }
        }, { passive: false });
    }

    render() {

        return (
            <div className="app">
                <Context.Consumer>
                    {(context) => (
                        <>
                            {/* STEP : {context.step} */}
                            {COMPONENTS.map((c, id) => {
                                const Component = c.component;
                                const isActive = c.activeSteps.includes(context.step);

                                return <StepWrapper key={id} isActive={isActive}>

                                    <Component {...context} loadReady = {this.state.loadReady}
                                                            percentLoaded = {this.state.percentLoaded}
                                                            isActive={isActive}
                                                            gltf={this.state.gltf}
                                                            occlusionMesh={this.occlusionMesh}
                                                            brfv5Manager={this.brfv5Manager}
                                                            brfv5Config={this.brfv5Config} />
                                </StepWrapper>;
                            })}
                        </>
                    )}
                </Context.Consumer>
            </div>
        )
    }
}

export default App;